.error-title {
  margin-top: 100px;
}

.error-btn {
    margin: 50px 0;
      background: #FF99D7;
      height: 50px;
      min-width: 250px;
      border: none;
      border-radius: 10px;
      color: #000;
      font-size: 20px;
      font-family: 'Crimson Pro', serif;
      position: relative;
      transition: 1s;
      -webkit-tap-highlight-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    
    .btn-link {
      color: #000;
      text-decoration: none;
    }
