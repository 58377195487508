@import url('https://fonts.googleapis.com/css2?family=Allan&family=Crimson+Pro&family=Montserrat&display=swap');
 /* BODY */
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	
}

body {
background: #EEEE;  /* fallback for old browsers */
/* 
background: -webkit-linear-gradient(to left, #E2E2E2, #C9D6FF);  /* Chrome 10-25, Safari 5.1-6 */
/*background: linear-gradient(to left, #E2E2E2, #C9D6FF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
 
margin: 0 50px;

}

h1 {
  	font-family: 'Crimson Pro', serif;
	color: #000;
  	font-size: 6vh;
		margin-top: 30px;
}

h2 {
  	font-family: 'Crimson Pro', serif;
	color: #000;
  	font-size: 18px;
	letter-spacing: 0.15em;
	line-height: 1.35;
}

h3 {
  	font-family: 'Crimson Pro', serif;
	color: #000;
  	font-size: 16px;
	letter-spacing: 0.15em;
	line-height: 1.35;
}

h4 {
  	font-family: 'Crimson Pro', serif;
	color: #000;
	font-size: 12px;
}

p {
	color: #000;
	font-family: 'Crimson Pro', serif;
	letter-spacing: 0.15em;
	font-size: 12px;
}



@media screen and (max-width: 900px) {

body {
	
	margin: 0 20px;
	
}

h1 {
	font-size: 8vh;
}
}