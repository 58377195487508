.contact-container {
    display: flex; 
    flex-direction: column;
}

.contact-wrapper {
    display: flex; 
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.contact-img {
    height: 50vh;
    width: auto;
    margin: 20px 30px;
    -webkit-filter: grayscale(100%);
}

.contact-img:hover {
    -webkit-filter: grayscale(0%);
}

.contact-tex-container {
    color: #fff;
    margin: 20px;
}

@media screen and (max-width: 900px) {
	.contact-wrapper {
        flex-direction: column;
    }
    .contact-img {
    height: 40vh;
    width: auto;
    }
}