.footer-container {
    
    margin-top: 50px;
}

.icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

a {
    color: #16213E;
}