.info-container {
    margin: 0 50px; 
}

.info-header {
    display: flex; 
    flex-direction: row;
    align-items: center;
}
.info-img {
    height: 35vh;
    width: auto;
    margin: 30px;
}

.info-img:hover {
    -webkit-filter: grayscale(100%);
}

.info-header-wrap {
    display: flex;
    flex-direction: column
}

.info-cv-container {
    color: #fff;
    /* max-width: 600px; */
    margin: 30px;
}

.info-link {
    font-family: 'Crimson Pro', serif;
    color: #000;
    font-size: 18px;
    letter-spacing: 0.15em;
    line-height: 1.35;
    text-decoration: none;
}

.h3 {
    font-family: 'Crimson Pro', serif;
    color: #000;
    font-size: 16px;
    letter-spacing: 0.15em;
    line-height: 1.35;
    padding-top: 20px;
}
span {
    font-weight: 100;
}

table {
    font-family: 'Crimson Pro', serif;
    color: #000;
    width: 100%;
    text-align: left;
}

th, td {
    text-align: left;
    padding-right: 15px;
}

@media screen and (max-width: 900px) {
    .info-header{
        flex-direction: column;
    }

	.info-container {
		flex-direction: column;
		align-items: center;
        margin: 0 15px;
	}
}