.about-container {
  display: flex;
  flex-direction: column;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.about-img {
  height: 50vh;
  width: auto;
  margin: 20px 30px;
}

.about-img:hover {
  -webkit-filter: grayscale(80%);
  }

.about-tex-container {
  color: #fff;
  margin: 20px;
}

.about-text {
  font-size: 16px;
  margin-bottom: 10px;
  max-width: 600px;
  line-height: 20px;
  letter-spacing: 0;
}

span {
  font-weight: 700;
}

@media screen and (max-width: 900px) {
  .about-wrapper {
    flex-direction: column;
  }

  .about-img {
    height: auto;
    width: 40vh;
  }
}