#btn {
	margin: 50px 0;
  background: #FF99D7;
  height: 50px;
  min-width: 250px;
  border: none;
  border-radius: 10px;
  color: #000;
  font-size: 20px;
  font-family: 'Crimson Pro', serif;
  position: relative;
  transition: 1s;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-link {
color: #000;
text-decoration: none;
}

#btn #circle {
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  transition: 500ms;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* #btn:hover {
  background: transparent;
  color: #100d2b;
} */

#btn:hover #circle {
  height: 50px;
  width: 250px;
  left: 0;
  border-radius: 0;
  border-bottom: 2px solid #eee;
}