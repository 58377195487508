.start-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 150px 0;
    height: 70vh;
}

.hero-text {
  font-size: 12vw;
  line-height: 12vw;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 900;
  background: url(https://ylvahansson.netlify.app/static/media/6.cf472152a76bd242cbb4.jpg);
  background-size: 40%;
  background-position: 50% 50%;
  -webkit-background-clip: text;
  color: rgba(0,0,0,0.08);
  animation: zoomout 10s ease 500ms forwards;
}

.start-img {
    height: 50vh;
    width: auto;
    margin: 20px 30px;
}

.start-img:hover {
  -webkit-filter: grayscale(100%);
}


@keyframes zoomout {
  from {
    background-size: 40%;
  }
  to {
    background-size: 10%;
  }
}


@media screen and (max-width: 900px) {
	.start-container {
		flex-direction: column;
		align-items: center;
    margin: 100px 0;
	}

   .start-img {
    height: 40vh;
    width: auto;
    }
}