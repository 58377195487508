.navbar {
    display: flex;
    align-items: center;
    background-color: #EEEE;;
    color: #000;
    font-family: 'Crimson Pro', serif;
    font-weight: 300;
    position: sticky;
    top: 0;
    margin: -20px;
}

.navbar-title {
    margin-right: auto;
    padding: 12px 14px;
    font-weight: 800;
    font-size: 22px;
    text-decoration: none;
    color: #000;
}

.navbar-item {
    padding: 16px 7px;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    color: #000;
    font-size: 20px;
}

.navbar-title:hover {
    font-weight: 100;
}

.navbar-item:hover {
    font-weight: 800;
}

@media screen and (max-width: 900px) {
    .navbar-item {
    font-size: 12px;
}
}