.reels-container {
    min-height: 100vh;
}

.video-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.video-wrapper {
    margin: 50px 20px;
}

.video-link {
    font-family: 'Crimson Pro', serif;
    color: #000;
    font-size: 18px;
    letter-spacing: 0.15em;
    line-height: 1.35;
    text-decoration: none;
    margin: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 18px;
}

svg {
    stroke-width: 1;
    margin-left: 10px;
}

@media screen and (max-width: 900px) {

    .video-wrapper {
        margin: 10px;
    }

    iframe {
        width: 320px;
        height: 200px;
    }


}

@media screen and (max-width: 900px) {
    iframe {
        width: 350px;
        height: 250px;
    }
}